// fonts
import './static/fonts/web-fonts.css'
// globals
import 'normalize.css'
import './src/global.css'

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }
}
