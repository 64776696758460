module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-tinacms/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/codebuild/output/src338720594/src/path/node_modules/gatsby-tinacms-git","id":"c697738b-367f-54ea-aee7-efebe247e085","name":"gatsby-tinacms-git","version":"0.4.7","pluginOptions":{"plugins":[],"defaultCommitMessage":"Edited with TinaCMS","defaultCommitName":"TinaCMS","defaultCommitEmail":"git@tinacms.org"},"nodeAPIs":["onCreateDevServer"],"browserAPIs":["onClientEntry"],"ssrAPIs":[]},{"resolve":"/codebuild/output/src338720594/src/path/node_modules/gatsby-tinacms-remark","id":"4e049a88-2f70-5135-883f-87a76d01585d","name":"gatsby-tinacms-remark","version":"0.7.13","pluginOptions":{"plugins":[]},"nodeAPIs":["setFieldsOnGraphQLNodeType"],"browserAPIs":[],"ssrAPIs":[]}],"sidebar":{"position":"displace","hidden":true}},
    },{
      plugin: require('../node_modules/gatsby-tinacms-git/gatsby-browser.js'),
      options: {"plugins":[],"defaultCommitMessage":"Edited with TinaCMS","defaultCommitName":"TinaCMS","defaultCommitEmail":"git@tinacms.org"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"PATH","short_name":"PATH","lang":"en","start_url":"/","background_color":"#0085ff","theme_color":"#0085ff","display":"standalone","icon":"src/images/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
